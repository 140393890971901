import { useDispatch } from "react-redux";
import { RouteProps } from "react-router-dom";
import { FxGuard } from "@coralblack/flax";
import { UserFlax } from "@maven-rest/sso/User.flax";
import { sessionSlice } from "@maven-rest/states/index";

interface MyInfoGuardProps extends RouteProps {}

export const CREDENTIAL_INVAILD_REDIRECT_TO = "credential_invaild_redirect_to";

export function MyInfoGuard({ component: Component }: MyInfoGuardProps) {
  const Comp: any = Component;
  const dispatch = useDispatch();

  return (
    <>
      <FxGuard
        key="static"
        api={{ ...UserFlax.getMyInfo({}) }}
        loading={() => <div className="app-spinner" />}
        render={(resp) => {
          if (resp) {
            return <>{<Comp currentUser={resp} />}</>;
          }

          return;
        }}
        error={(err) => {
          if (err?.code === "CREDENTIAL-INVALID") {
            sessionStorage.setItem(CREDENTIAL_INVAILD_REDIRECT_TO, document.location.href);
            dispatch(sessionSlice.actions.signOut());
            return <>&nbsp;</>;
          }

          return (
            <>
              [{err?.code || "UNKNOWN"}] {err?.message || "An error has occurred."}
            </>
          );
        }}
      />
    </>
  );
}
