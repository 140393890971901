import React, { useEffect, useState } from "react";
import { LoLang } from "maven-lib/dist/consts";
import { useDispatch } from "react-redux";
import { useRequest } from "@coralblack/flax";
import { authApi } from "maven-lib/dist/states/apis/auth";
import { sessionSlice } from "maven-lib/dist/states/reducers/session";
import { Timer } from "./Timer";
import { Waken } from "./Waken";
import { CrDialog } from "../../components/base/CrDialog";
import { nl2br } from "../../components/helper/nl2br";
import { useInterval } from "../../hooks/useInterval";

interface AuthTokenManagerProps {
  localeLanguage?: { lolang?: LoLang };
}

const HEART_BEAT_INTERVAL = 1000 * 60; // 1m
const CRITERIA_SECS = 60 * 15; // 15m

export function AuthTokenManager(props: AuthTokenManagerProps) {
  const lolang = props?.localeLanguage?.lolang;
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const { request } = useRequest(
    { ...authApi.awakeAuthToken },
    {
      success: (resp) => {
        dispatch(sessionSlice.actions.updateExpiryAt({ ...resp }));
      },
      error: (error) => {
        if (error.code === "CREDENTIAL-INVALID" || error.code === "CREDENTIAL-INVALID-BY-NEW-TOKEN") {
          dispatch(sessionSlice.actions.signOut());
        }
      },
      done: () => {
        setIsBusy(false);
      },
    }
  );

  useInterval(() => {
    if (!isBusy) {
      setIsBusy(true);
      request();
    }
  }, HEART_BEAT_INTERVAL);

  useEffect(() => {
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [id, setId] = useState(new Date().getTime());
  const [secs, setSecs] = useState(0);
  const [ignoredId, setIgnoredId] = useState(0);
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const { request: authTokenRefreshRequest, response: authTokenRefreshResponse } = useRequest(
    { ...authApi.refreshAuthToken },
    {
      success: (resp) => {
        setDisabled(true);
        setTimeout(() => {
          setDisabled(false);
        }, 10 * 1000);

        dispatch(sessionSlice.actions.refreshSignIn({ ...resp }));
        setId(new Date().getTime());

        return lolang === LoLang.EN ? "Your login session has extended." : "세션 유효시간이 연장되었습니다.";
      },
      error: (error) => {
        if (error?.code === "CREDENTIAL-INVALID") {
          dispatch(sessionSlice.actions.signOut());
        }

        return lolang === LoLang.EN
          ? `Failed to extend login session. (${error?.message})`
          : `세션 유효시간 연장에 실패하였습니다. (${error?.message})`;
      },
    }
  );

  return (
    <>
      <CrDialog
        header="Maven Clinical Cloud"
        visibility={dialogVisibility}
        onConfirm={() => {
          authTokenRefreshRequest();
          setDialogVisibility(!dialogVisibility);
          setIgnoredId(id);
        }}
        onCancel={() => {
          setDialogVisibility(!dialogVisibility);
          setIgnoredId(id);
        }}
        confirm="Extend"
        cancel="Cancel"
      >
        {nl2br(
          lolang === LoLang.EN
            ? `Your login session will expire in ${Math.floor(secs / 60)}min ${secs % 60}s.\nWill you extend your login session?`
            : `${Math.floor(secs / 60)}분 ${secs % 60}초 후에 로그인 세션이 만료됩니다.\n연장하시겠습니까?`
        )}
      </CrDialog>
      <Timer
        awakeRequest={request}
        refreshRequest={authTokenRefreshRequest}
        busy={authTokenRefreshResponse.busy}
        disabled={disabled}
        onTick={(secs) => {
          setSecs(secs);

          if (id !== ignoredId) {
            setDialogVisibility(secs > 0 && secs <= CRITERIA_SECS);
          }
        }}
        defaultTitle={lolang === LoLang.EN ? "Extend login session" : "로그인 시간 연장하기"}
      />
      <Waken />
    </>
  );
}
