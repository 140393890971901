export enum MemberInvitationStatus {
  None = "NONE",
  Wait = "WAIT",
  Sending = "SENDING",
  SendingFailed = "SENDING-FAILED",
  Sent = "SENT",
  Reviewing = "REVIEWING",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
}

export class MemberInvitation {
  id: bigint;
  memberId: bigint;
  userId: bigint;
  name: string;
  email: string;
  inviteeEmail: string;
  status: MemberInvitationStatus;
  token: string;
}

// GetMyMemberInvitations

export class GetMyPendingMemberInvitationsRequest {}

export class GetMyPendingMemberInvitationsResponse {
  invitations: MemberInvitation[];
}

// GetMemberInvitationByToken

export class GetMemberInvitationByTokenRequest {
  token: string;
}

export class GetMemberInvitationResponse {
  invitation: MemberInvitation;
}

// AcceptMemberInvitationByToken

export class AcceptMemberInvitationByTokenRequest {
  token: string;
}

export class AcceptMemberInvitationByTokenResponse {
  invitation: MemberInvitation;
}

// RejectMemberInvitationByToken

export class RejectMemberInvitationByTokenRequest {
  token: string;
}

export class RejectMemberInvitationByTokenResponse {
  invitation: MemberInvitation;
}
