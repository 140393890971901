import React from "react";
import { classNames } from "maven-lib/dist/utils/misc";

interface CrSectionProps {
  title?: React.ReactNode;
  titleRight?: React.ReactNode;
  titleFontSize?: "normal" | "small";
  innerNoPadding?: boolean;
  children?: React.ReactNode;
  shadow: boolean;
  className?: string;
}

export function CrSection(props: CrSectionProps) {
  const { title, titleRight, titleFontSize, className, innerNoPadding, children, shadow } = props;

  return (
    <>
      <div className={classNames("cr-section", className, { "--without-shadow": !shadow })}>
        <div className={classNames("cr-section-inner", innerNoPadding ? "cr-section-inner-no-padding" : "")}>
          <div className="cr-section-inner-wrap">
            <div className="cr-section-inner-top-row">
              {title && (
                <div className={classNames("cr-section-inner-title", titleFontSize ? `cr-section-inner-title-${titleFontSize}` : "")}>
                  {title}
                </div>
              )}
              {titleRight && <div className="cr-section-inner-title-right">{titleRight}</div>}
            </div>
            <div className="cr-section-inner-content">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

CrSection.defaultProps = {
  shadow: true,
};
