import { Redirect, Route, RouteProps, Switch, useRouteMatch } from "react-router-dom";
import { navis, resources } from "./navi";
import { MemberInvitationRoute } from "./routes/MemberInvitationRoute";
import Layout from "@maven-surface/layouts/Layout";

interface Props extends RouteProps {}

export default function MemberInvitationLayout(props: Props) {
  const match = useRouteMatch();

  return (
    <>
      <Layout
        logoLink="/"
        currentUser={undefined}
        roles={undefined}
        currentRole={undefined}
        navi={{ navis, resources }}
        defaultCollapsed={true}
        sidebarPinTogglable={false}
        languageChangeable={false}
        enableLoginHistory={false}
        enableProfile={false}
        helpButton={false}
      >
        <Switch>
          <Route path={`${match.url}`} render={() => <MemberInvitationRoute />} />
          <Redirect from="*" to="/404?r=sx" />
        </Switch>
      </Layout>
    </>
  );
}
