import React, { useEffect, useRef, useState } from "react";
import { RouteProps, useLocation } from "react-router-dom";
import { notify } from "@coralblack/flax";
import { LoLang } from "maven-lib/dist/consts/common";
import { StudyUser, User } from "maven-lib/dist/dto/entity";
import { StudyUserRolePriv, StudyUserRoles } from "maven-lib/dist/dto/study";
import { UserRoles } from "maven-lib/dist/dto/user";
import { classNames } from "maven-lib/dist/utils/misc";
import { Drawer } from "./Drawer";
import Footer from "./Footer";
import Header, { HeaderHelpButtonProp } from "./Header";
import Sidebar, { Navi, NaviDivider, NaviGroup } from "./Sidebar";
import { UserContextProvider } from "./UserContext";
import { CrContextualMenuItem } from "../components/base/CrContextualMenu";
import { IS_NEED_NOTIFY_LAST_LOGINTIME } from "../pages/auth/layout/Auth";
import dayjs from "dayjs";

interface LayoutProps extends RouteProps {
  currentUser: User | undefined;
  currentStudyUser?: StudyUser;
  children: React.ReactNode;
  childrenHeaderNavi?: React.ReactNode;
  theme: "primary" | "secondary";
  logoLink: string;
  routeParams?: { [key: string]: string };
  roles: UserRoles | StudyUserRoles | undefined;
  currentRole: StudyUserRolePriv | undefined;
  defaultCollapsed?: boolean;
  sidebarPinTogglable?: boolean;
  languageChangeable?: boolean;
  localeLanguage: { lolang: LoLang };
  navi: {
    navis: (Navi | NaviGroup | NaviDivider)[];
    resources?: (Navi | NaviGroup | NaviDivider)[];
  };
  enableLoginHistory: boolean;
  enableProfile: boolean;
  helpButton?: HeaderHelpButtonProp;
  lastLoginTimeAlertMessage?: string;
  headerDropdownItems?: CrContextualMenuItem[];
  footer?: React.ReactChild;
  version?: string;
}
export default function Layout(props: LayoutProps) {
  const { currentUser, currentStudyUser, defaultCollapsed, sidebarPinTogglable, logoLink, footer, helpButton, version } = props;
  const appLayoutRef = useRef(null);
  const [sidebarCollapsedState, setSidebarCollapsed] = useState(!!(localStorage.getItem("sidebarCollapsed") === "Fold" || false));
  const sidebarCollapsed = defaultCollapsed || sidebarCollapsedState;
  const handleSidebarPinToggle = () => {
    setSidebarCollapsed(!sidebarCollapsed);
    localStorage.setItem("sidebarCollapsed", sidebarCollapsed === true ? "UnFold" : "Fold");
  };

  const [sidebarClosed, setSidebarClosed] = useState(false);
  const handleSidebarClose = () => {
    setSidebarClosed(!sidebarClosed);
  };

  const [drawerOpen, setDrawerOpenState] = useState(false);
  const setDrawerOpen = (val: boolean) => {
    if (val) {
      document.getElementById("html")?.classList?.add("full");
    } else {
      document.getElementById("html")?.classList?.remove("full");
    }

    setDrawerOpenState(val);
  };

  const sidebarCollapseForceAfterRedirectEnabled = false;
  const [sidebarCollapsedForce, setSidebarCollapsedForce] = useState(false);
  const location = useLocation();

  useEffect(() => {
    ((document.getElementById("html") || {}) as any).scrollTop = 0;
    setSidebarClosed(false);
    setSidebarCollapsedForce(true);

    setTimeout(() => {
      setSidebarCollapsedForce(false);
    }, 380);
  }, [location]);

  const bodyClickHandler = (e: any) => {
    if (drawerOpen) {
      setDrawerOpenState(false);
      document.getElementById("html")?.classList?.remove("full");
    }
  };

  useEffect(() => {
    window.addEventListener("click", bodyClickHandler);

    return () => {
      window.removeEventListener("click", bodyClickHandler);
    };
  });

  useEffect(() => {
    const isNeedNotifyLastLoginTime = sessionStorage.getItem(IS_NEED_NOTIFY_LAST_LOGINTIME);

    if (isNeedNotifyLastLoginTime === "true") {
      !!currentUser?.lastLoginTime &&
        notify(`${props.lastLoginTimeAlertMessage}: ${dayjs(currentUser.lastLoginTime).format("YYYY/MM/DD HH:mm:ss")}`, "SUCC");
      sessionStorage.removeItem(IS_NEED_NOTIFY_LAST_LOGINTIME);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const productName = String(process.env.REACT_APP_NAME || "")
    .toLowerCase()
    .split(" ")
    .join("-");

  return (
    <>
      <UserContextProvider user={currentUser || null} lang={props.localeLanguage?.lolang}>
        <div
          ref={appLayoutRef}
          className={classNames(
            productName,
            "app-layout",
            `${props.theme}-theme`,
            { "drawer-open": drawerOpen },
            { "sb-collapsed": sidebarCollapsed },
            { "sb-collapsed-force": sidebarCollapseForceAfterRedirectEnabled && sidebarCollapsedForce },
            { "sb-opened": sidebarClosed }
          )}
        >
          <div className={classNames("app-sidebar", props.navi.navis.length === 0 && "state--no-navis")}>
            <Sidebar
              version={version}
              logoLink={logoLink}
              onSidebarPinToggle={handleSidebarPinToggle}
              onSidebarClose={handleSidebarClose}
              sidebarPinTogglable={sidebarPinTogglable}
              navi={props.navi}
              currentUser={props.currentUser}
              roles={props.currentRole || props.roles}
              routeParams={props.routeParams}
            />
          </div>
          <div className="app-header">
            <Header
              currentUser={currentUser}
              currentStudyUser={currentStudyUser}
              currentRole={props.currentRole}
              hamburgerToggleState={handleSidebarClose}
              hideHamburger={props.navi.navis.length === 0}
              drawerToggleState={[drawerOpen, setDrawerOpen]}
              childrenNavi={props.childrenHeaderNavi}
              languageChangeable={props.languageChangeable}
              localeLanguage={props.localeLanguage}
              enableLoginHistory={props.enableLoginHistory}
              enableProfile={props.enableProfile}
              dropdownItems={props.headerDropdownItems}
              helpButton={helpButton}
            />
          </div>
          <div className="app-drawer">
            <Drawer drawerToggleState={[drawerOpen, setDrawerOpen]} />
          </div>
          <div className="app-main">
            <div className="app-content">{props.children}</div>
            <div className="app-footer">
              {!!footer && <>{footer}</>}
              {!footer && <Footer />}
            </div>
          </div>
        </div>
      </UserContextProvider>
    </>
  );
}

Layout.defaultProps = {
  theme: "primary",
  logoLink: "/",
  languageChangeable: true,
  localeLanguage: LoLang.KO,
  enableLoginHistory: true,
  enableProfile: true,
  lastLoginTimeAlertMessage: "최근 로그인 시간",
};
