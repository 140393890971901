import { createContext, useContext } from "react";
import { LoLang } from "maven-lib/dist/consts";
import { User } from "maven-lib/dist/dto/entity";

interface UserContextProps {
  user: User | null;
  lang: LoLang;
}

const UserContext = createContext<UserContextProps>({
  user: null,
  lang: LoLang.KO,
});

function UserContextProvider({ user, children, lang }: { user: User | null; lang?: LoLang; children: React.ReactNode }) {
  return <UserContext.Provider value={{ user, lang: lang || LoLang.KO }}>{children}</UserContext.Provider>;
}

function useUserContext() {
  return useContext(UserContext);
}

export { UserContext, UserContextProvider, useUserContext };
