import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RolePrivilegeType } from "maven-lib/dist/consts/role";
import { User } from "maven-lib/dist/dto/entity";
import { StudyUserRolePriv, StudyUserRoles } from "maven-lib/dist/dto/study";
import { UserRoles } from "maven-lib/dist/dto/user";
import { layoutSlice } from "maven-lib/dist/states/reducers/layout";
import { classNames, hasOwnProperty, isAuthorized } from "maven-lib/dist/utils/misc";
import { Iconish } from "../components/interface/Icon";
import { useRoute } from "../hooks/useRoute";

export type Navis = (Navi | NaviGroup | NaviDivider)[];

export interface SidebarProps {
  logoLink: string;
  onSidebarPinToggle: () => void;
  onSidebarClose: () => void;
  routeParams?: { [key: string]: string };
  currentUser: User | undefined;
  roles: UserRoles | StudyUserRoles | StudyUserRolePriv | undefined;
  sidebarPinTogglable?: boolean;
  navi: {
    navis: (Navi | NaviGroup | NaviDivider)[];
    resources?: (Navi | NaviGroup | NaviDivider)[];
  };
  version?: string;
}

interface LinkProp {
  url: string;
  target: "_blank" | "_self";
}

export interface Navi {
  icon: Iconish;
  label: string;
  link: string | LinkProp;
  slug?: string | string[];
  priv?: RolePrivilegeType[];
  isHidden?: boolean;
}

export interface NaviGroup {
  icon: Iconish;
  label: string;
  slug: string;
  navis: Navi[];
  foldable?: boolean;
}

export interface NaviDivider {
  divider: boolean;
  priv?: RolePrivilegeType[];
  isHidden?: boolean;
}

interface SidebarNaviProps {
  routeParams?: { [key: string]: string };
  navi: Navi;
}

interface SidebarNaviGroupProps {
  routeParams?: { [key: string]: string };
  naviGroup: NaviGroup;
}

interface SidebarNaviDividerProps {
  naviDivider: NaviDivider;
}

const paramsMapped = (params: { [key: string]: string }, path: string | string[]) => {
  const paths = Array.isArray(path) ? path : [path];

  return paths.map((val) => {
    if (typeof val !== "string") return val;
    Object.keys(params || {}).forEach((k) => {
      val = val.split(`:${k}`).join(params[k]);
    });

    return val;
  });
};

function SidebarNavi(props: SidebarNaviProps) {
  const [highlight, setHighlight] = useState(false);
  const location = useLocation();

  const slugs = props.navi.slug ? paramsMapped(props.routeParams || {}, props.navi.slug) : null;
  const [link] = paramsMapped(props.routeParams || {}, props.navi.link as string);

  useEffect(() => {
    if (slugs) setHighlight(location.pathname === link || !!slugs.find((slug) => location.pathname.startsWith(slug)));
    else setHighlight(location.pathname.startsWith(link));
  }, [location, link, slugs]);

  // TODO: Icon ENUM으로 관리할 수 있도록 개선 필요 (2023.06.07)
  const iconClassName = ((icn) => {
    return ((icn: Iconish) => {
      switch (typeof icn === "string" ? icn : icn.icon) {
        case "mavenLogo":
          return "maven-logo-icon";
        case "docsNavIcon":
          return "maven-docs-nav-icon";
        case "safetyNavIcon":
          return "maven-safety-nav-icon";
        case "tmfNavIcon":
          return "maven-tmf-nav-icon";
        case "vdrNavIcon":
          return "maven-vdr-nav-icon";
        default: {
          return typeof icn === "string" || !icn.filled ? "notranslate material-icons-outlined" : "notranslate material-icons";
        }
      }
    })(icn);
  })(props.navi.icon);

  const iconName = ((icn) => {
    return ((icn: Iconish) => {
      switch (icn) {
        case "mavenLogo":
        case "docsNavIcon":
        case "safetyNavIcon":
        case "tmfNavIcon":
        case "vdrNavIcon":
          return;
        default: {
          return typeof icn === "string" ? icn : icn.icon;
        }
      }
    })(icn);
  })(props.navi.icon);

  const LinkTag = () => (
    <>
      <i className={classNames(iconClassName, "ico")}>{iconName}</i> <span>{props.navi.label}</span>
    </>
  );

  let [linkTo] = paramsMapped(props.routeParams || {}, props.navi.link as string);

  return (
    <li className={["nav", highlight ? "highlight" : ""].filter((e) => e).join(" ")}>
      {typeof props.navi.link === "string" ? (
        <Link className="btn" to={linkTo}>
          <LinkTag />
        </Link>
      ) : (
        <a className="btn" href={props.navi.link.url} target={props.navi.link.target}>
          <LinkTag />
        </a>
      )}
    </li>
  );
}

function SidebarNaviGroup(props: SidebarNaviGroupProps) {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const matched = location.pathname.startsWith(props.naviGroup.slug);

    if (matched) {
      setOpen(true);
    }
  }, [location, props.naviGroup.slug]);

  return (
    <>
      <li>
        <ul className={["group", open ? "highlight" : ""].filter((e) => e).join(" ")}>
          <li className="head">
            <button className="btn" onClick={() => setOpen(!open)}>
              <i className="notranslate material-icons-outlined ico">{props.naviGroup.icon}</i> <span>{props.naviGroup.label}</span>
            </button>
          </li>
          {props.naviGroup.navis.map((navi, naviIdx) => (
            <SidebarNavi key={naviIdx} navi={navi} routeParams={props.routeParams} />
          ))}
        </ul>
      </li>
    </>
  );
}

function SidebarNaviDivider(props: SidebarNaviDividerProps) {
  return <li className="divider" />;
}

interface SidebarNavisProps {
  routeParams?: { [key: string]: string };
  className?: string;
  currentUser: User | undefined;
  roles: UserRoles | StudyUserRoles | StudyUserRolePriv | undefined;
  navis: (Navi | NaviGroup | NaviDivider)[];
}

function SidebarNavis(props: SidebarNavisProps) {
  if (props.navis.length === 0) {
    return null;
  }

  return (
    <ul className={["navi", props.className].filter((e) => e).join(" ")}>
      {props.navis.map((navi, naviIdx) => {
        if (hasOwnProperty(navi, "isHidden") && navi.isHidden) return null;

        if ((navi as NaviDivider).divider) {
          if (isAuthorized((navi as Navi).priv, props.roles) || isAuthorized((navi as Navi).priv, props.currentUser?.roles)) {
            return <SidebarNaviDivider key={naviIdx} naviDivider={navi as NaviDivider} />;
          } else {
            return null;
          }
        } else if ((navi as NaviGroup).navis) {
          return <SidebarNaviGroup key={naviIdx} naviGroup={navi as NaviGroup} routeParams={props.routeParams} />;
        } else if ((navi as Navi).label) {
          if (isAuthorized((navi as Navi).priv, props.roles) || isAuthorized((navi as Navi).priv, props.currentUser?.roles)) {
            return <SidebarNavi key={naviIdx} navi={navi as Navi} routeParams={props.routeParams} />;
          } else {
            return null;
          }
        } else {
          return null;
        }
      })}
    </ul>
  );
}

export default function Sidebar(props: SidebarProps) {
  const { sidebarPinTogglable, logoLink, version } = props;
  const { location } = useRoute();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(layoutSlice.actions.navigated());
  }, [dispatch, location]);

  return (
    <>
      <div className="app-sidebar-wrapper">
        <div className="app-sidebar-logo">
          <Link to={logoLink} className="logo">
            <i className="notranslate material-icons-outlined">eco</i> <span>{process.env.REACT_APP_NAME}</span>
          </Link>
          {sidebarPinTogglable !== false && (
            <>
              <i className="pin" onClick={props.onSidebarPinToggle} />
              <i className="close" onClick={props.onSidebarClose} />
            </>
          )}
        </div>
        <div className="app-sidebar-nav">
          <div>
            <SidebarNavis currentUser={props.currentUser} roles={props.roles} navis={props.navi.navis} routeParams={props.routeParams} />
            {props.navi.resources ? (
              <SidebarNavis
                currentUser={props.currentUser}
                roles={props.roles}
                navis={props.navi.resources}
                routeParams={props.routeParams}
                className="secondary"
              />
            ) : null}
          </div>
          {version && <div className="app-sidebar-version">version {version}</div>}
        </div>
      </div>
    </>
  );
}

Sidebar.defaultProps = {
  logoLink: "/",
};
