import {
  AcceptMemberInvitationByTokenRequest,
  AcceptMemberInvitationByTokenResponse,
  GetMemberInvitationByTokenRequest,
  GetMemberInvitationResponse,
  GetMyPendingMemberInvitationsRequest,
  GetMyPendingMemberInvitationsResponse,
  RejectMemberInvitationByTokenRequest,
  RejectMemberInvitationByTokenResponse,
} from "./MemberInvitation.dto";
import { MemberInvitationUrl } from "./MemberInvitation.url";
import { restReq } from "../common/cyan.types";
import { FxApiRequestData, FxRequestData } from "../common/flax.types";
import { extractKey } from "../common/HttpRequest";

export class MemberInvitationFlax {
  public static getMyPendingMemberInvitations(
    data: FxRequestData<GetMyPendingMemberInvitationsRequest>
  ): FxApiRequestData<GetMyPendingMemberInvitationsResponse> {
    return {
      ...restReq(
        process.env.REACT_APP_AUTH_URL || process.env.REACT_APP_AUTH_BE_URL,
        MemberInvitationUrl.getMyPendingMemberInvitations,
        data
      ),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static getMemberInvitationByToken(
    data: FxRequestData<GetMemberInvitationByTokenRequest>
  ): FxApiRequestData<GetMemberInvitationResponse> {
    const token = extractKey(data, "token");

    return {
      ...restReq(
        process.env.REACT_APP_AUTH_URL || process.env.REACT_APP_AUTH_BE_URL,
        MemberInvitationUrl.getMemberInvitationByToken(token),
        data
      ),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static acceptMemberInvitationByToken(
    data: FxRequestData<AcceptMemberInvitationByTokenRequest>
  ): FxApiRequestData<AcceptMemberInvitationByTokenResponse> {
    const token = extractKey(data, "token");

    return {
      ...restReq(
        process.env.REACT_APP_AUTH_URL || process.env.REACT_APP_AUTH_BE_URL,
        MemberInvitationUrl.acceptMemberInvitationByToken(token),
        data
      ),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static rejectMemberInvitationByTokenember(
    data: FxRequestData<RejectMemberInvitationByTokenRequest>
  ): FxApiRequestData<RejectMemberInvitationByTokenResponse> {
    const token = extractKey(data, "token");

    return {
      ...restReq(
        process.env.REACT_APP_AUTH_URL || process.env.REACT_APP_AUTH_BE_URL,
        MemberInvitationUrl.rejectMemberInvitationByToken(token),
        data
      ),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
