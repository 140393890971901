import { useStore } from "react-redux";
import { useParams } from "react-router-dom";
import { FxGuard } from "@coralblack/flax";
import { CrButton } from "@maven-surface/components/base/CrButton";
import { CrInput } from "@maven-surface/components/base/CrInput";
import { CrInputGroup } from "@maven-surface/components/base/CrInputGroup";
import { CrRequestButton } from "@maven-surface/components/base/CrRequestButton";
import { CrSection } from "@maven-surface/components/base/CrSection";
import { useRoute } from "@maven-surface/hooks";
import { AUTH_MEMBER_LANG } from "@maven-msg/lang";
import { MvnMessage } from "@maven-msg/lib/Message";
import { GetMemberInvitationResponse, MemberInvitation, MemberInvitationStatus } from "@maven-rest/auth/MemberInvitation.dto";
import { MemberInvitationFlax } from "@maven-rest/auth/MemberInvitation.flax";
import { RootState } from "@maven-rest/states";

import "./invitationView.scss";

interface Props {}

function MemberInvitationViewInner({ invitation, token }: { invitation: MemberInvitation; token: string }) {
  const session = useStore<RootState>().getState().session;
  const { pushPath } = useRoute();

  if (invitation.status === MemberInvitationStatus.Accepted) {
    document.location.href = "/";
    return <></>;
  }

  return (
    <>
      <CrSection
        className="maven-portal--member-invitation"
        title={
          <>
            {"You have an invitation to join "}
            <span>Maven Clinical Cloud</span>
          </>
        }
      >
        <CrInputGroup>
          <CrInput
            label="Invited by"
            value={`${invitation.name ? `${invitation.name} (${invitation.email})` : `${invitation.email || "Unknown"}`}`}
            readonly
            disabled
          />
          <CrInput label="Invitee" value={invitation.inviteeEmail} readonly disabled />
          {!!session.token && (
            <CrRequestButton
              color="primary"
              api={{
                ...MemberInvitationFlax.acceptMemberInvitationByToken({ token }),
              }}
              delegate={{
                success: () => {
                  document.location.reload();
                  return MvnMessage(AUTH_MEMBER_LANG.AUTH_MEMBER_INVITATION_ACCEPT_SUCC);
                },
                error: (error) => MvnMessage(AUTH_MEMBER_LANG.AUTH_MEMBER_INVITATION_ACCEPT_FAIL, { message: error }),
              }}
            >
              Join
            </CrRequestButton>
          )}
          {!session.token && (
            <CrButton
              color="primary"
              onClick={() =>
                pushPath(
                  `/auth/sign-in?redirect=${encodeURIComponent(document.location.href)}&email=${encodeURIComponent(
                    invitation.inviteeEmail
                  )}`
                )
              }
            >
              Join
            </CrButton>
          )}
        </CrInputGroup>
      </CrSection>
    </>
  );
}

export function MemberInvitationView(props: Props) {
  const { token } = useParams<{ token: string }>();

  return (
    <>
      <FxGuard<GetMemberInvitationResponse>
        api={{ ...MemberInvitationFlax.getMemberInvitationByToken({ token }) }}
        render={(resp) => (
          <>
            <MemberInvitationViewInner invitation={resp?.invitation!} token={token} />
          </>
        )}
      />
    </>
  );
}
