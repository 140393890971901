import React /* , { Dispatch } */ from "react";

interface DrawerProps {
  drawerToggleState: Array<any>; // [boolean, Dispatch<boolean>];
}

export function Drawer(props: DrawerProps) {
  const toggleDrawer = () => props.drawerToggleState[1](!props.drawerToggleState[0]);

  return (
    <>
      <div className="app-drawer-wrapper">
        <div className="app-drawer-curtain" onClick={toggleDrawer} />
        <div className="app-drawer-content" onClick={(e) => e.stopPropagation()}>
          <div className="app-drawer-head" onClick={(e) => e.preventDefault()}>
            <h1 className="title">Notifications</h1>
            <button className="close" onClick={toggleDrawer}>
              close
            </button>
          </div>
          <ul className="items">
            {"*"
              .repeat(30)
              .split("")
              .map((_, i) => (
                <li key={i} className="item">
                  <a href="/">
                    <strong>Notification {i}</strong>
                    <p>
                      {"*"
                        .repeat(((i + 3) % 4) * 3 + i)
                        .split("")
                        .map(() => "Noti")
                        .join(" ")}
                    </p>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}
