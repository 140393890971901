import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { MemberInvitationView } from "@pages/my/member/invitation/InvitationView";

interface Props {}

export function MemberInvitationRoute(props: Props) {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${match.url}/:token`} render={() => <MemberInvitationView />} />
        <Redirect from="*" to="/404?st" />
      </Switch>
    </>
  );
}
