import React, { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteProps, Switch, useLocation } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { useRequest } from "@coralblack/flax";
import { MavenService } from "maven-lib/dist/dto/entity";
import { authApi } from "maven-lib/dist/states/apis/auth";
import { orchestraMsaApi } from "maven-lib/dist/states/apis/orchestra";
import { userApi } from "maven-lib/dist/states/apis/user";
import { sessionSlice } from "maven-lib/dist/states/reducers/session";
import { classNames } from "maven-lib/dist/utils/misc";
import { DuplicateLoginDialog, DuplicateLoginDialogMessageProps } from "./dialogs/DuplicateLoginDialog";
import { InitEmailVerifyPasswordDialog, InitEmailVerifyPasswordDialogMessageProps } from "./dialogs/InitEmailVerifyPasswordDialog";
import { InitPasswordDialog, InitPasswordDialogMessageProps } from "./dialogs/InitPasswordDialog";
import { UpdatePasswordDialog, UpdatePasswordDialogMessageProps } from "./dialogs/UpdatePasswordDialog";
import { CrButton } from "../../../components/base/CrButton";
import { v4 as uuidv4 } from "uuid";

interface AuthLayoutProps extends RouteProps {
  service: MavenService;
  messages: {
    code: {
      initialPasswordNotSet: string;
      passwordNotConfigured: string;
      passwordChangeCycleExceed: string;
      previousAuthTokenAlive: string;
    };
  };
  initEmailVerifyPasswordDialog: {
    messages: InitEmailVerifyPasswordDialogMessageProps;
  };
  initPasswordDialog?: {
    messages?: InitPasswordDialogMessageProps;
  };
  updatePasswordDialog?: {
    messages?: UpdatePasswordDialogMessageProps;
  };
  duplicateLoginDialog?: {
    messages?: DuplicateLoginDialogMessageProps;
  };
}

const queue: Array<string> = [];

export const IS_NEED_NOTIFY_LAST_LOGINTIME = "isNeedNotifyLastLoginTime";

export function AuthLayout(props: AuthLayoutProps) {
  // TODO 하위호환을 위한 optional 처리, 추후 문제가 없을 경우, messages에 대해 필수값으로 수정 필요
  // const { service, messages, initPasswordDialog, initEmailVerifyPasswordDialog, updatePasswordDialog, duplicateLoginDialog } = props;
  const { service, messages, initEmailVerifyPasswordDialog } = props;
  const initPasswordDialog = props?.initPasswordDialog;
  const updatePasswordDialog = props?.updatePasswordDialog;
  const duplicateLoginDialog = props?.duplicateLoginDialog;

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const redirectTo = (state as any)?.from?.pathname;

  const [emailVal, setEmailVal] = useState("");
  const [passwordVal, setPasswordVal] = useState("");
  const [initPasswordDialogVisibility, setInitPasswordDialogVisibility] = useState(false);
  const [initEmailVerifyPasswordDialogVisibility, setInitEmailVerifyPasswordDialogVisibility] = useState(false);
  const [updatePasswordDialogVisibility, setUpdatePasswordDialogVisibility] = useState(false);
  const [duplicateLoginDialogVisibility, setDuplicateLoginDialogVisibility] = useState(false);
  const [isPasswordConfiguredState, setIsPasswordConfiguredState] = useState("");

  const { request: loginRequest, response: loginResponse } = useRequest(
    { ...authApi.createAuthToken },
    {
      success: (resp) => {
        sessionStorage.setItem(IS_NEED_NOTIFY_LAST_LOGINTIME, "true");
        dispatch(sessionSlice.actions.signIn({ ...resp, redirectTo }));
      },
      error: (error) => {
        if (error?.code === messages.code.initialPasswordNotSet) {
          setInitPasswordDialogVisibility(true);
        } else if (error?.code === messages.code.passwordNotConfigured) {
          setInitEmailVerifyPasswordDialogVisibility(true);
        } else if (error?.code === messages.code.passwordChangeCycleExceed) {
          setUpdatePasswordDialogVisibility(true);
        } else if (error?.code === messages.code.previousAuthTokenAlive) {
          setDuplicateLoginDialogVisibility(true);
        }
        return { title: "Login Error", message: error?.message || "An error has occurred." };
      },
    }
  );

  const cache = (key: string, val?: boolean): boolean => {
    const k = `auth:cfg:${key}`;

    if (val !== undefined) {
      localStorage.setItem(k, JSON.stringify({ val, exp: new Date().getTime() + (val === true ? 3600 * 24 * 30 : 5) * 1000 }));
    }

    const cached = localStorage.getItem(k);

    if (!cached) return null;

    try {
      const { val, exp } = JSON.parse(cached);

      if (new Date().getTime() > exp) return null;
      return val;
    } catch (e) {
      return null;
    }
  };

  const { request: checkIsPasswordConfiguredReq, cancel: cancelCheckIsPasswordConfigured } = useRequest(
    { ...userApi.checkIsPasswordConfigured, cacheMaxAge: 3600 * 24 },
    {
      success: (resp) => {
        cache(resp.email, resp.isPasswordConfigured);

        const q = queue.pop();

        if (q && typeof cache(q) !== "boolean") {
          const res = checkIsPasswordConfigured(q);

          if (res === false) queue.push(q);
        }

        setIsPasswordConfiguredState(uuidv4());
      },
    }
  );

  const { request: wakenMss } = useRequest({ ...orchestraMsaApi.waken });

  const checkIsPasswordConfigured = (email: string) => {
    const cached = cache(email);

    if (typeof cached === "boolean") return cached;

    checkIsPasswordConfiguredReq({ data: { email } });
    return true;
  };

  return (
    <>
      <Switch>
        <Route
          exact={true}
          path="/auth"
          render={() => (
            <div className="app-layout">
              <div className="app-fullpage">
                <div className="app-auth">
                  <div className="app-auth-wrapper">
                    <div className="app-auth-side">
                      <div className="app-auth-side-wrapper">
                        <strong>{process.env.REACT_APP_NAME}</strong>
                        <p>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </p>
                      </div>
                    </div>
                    <div className="app-auth-content">
                      <div className="app-auth-content-wrapper">
                        <h1 className={classNames("logo", service.toLocaleLowerCase())}>{process.env.REACT_APP_NAME}</h1>
                        <div className="header">
                          <h2 className="title">Sign in</h2>
                          <p className="help">
                            Any problem?{" "}
                            <a href="https://www.jnpmedi.com" target="_blank" rel="noopener noreferrer">
                              Ask us anything!
                            </a>
                          </p>
                        </div>
                        <div className="form">
                          <fieldset>
                            <legend>Sign in</legend>
                            <label htmlFor="email">
                              <input
                                ref={emailRef}
                                id="email"
                                placeholder="Email"
                                className="email"
                                type="email"
                                onBlur={() => {
                                  wakenMss();
                                }}
                                onChange={(e) => {
                                  setEmailVal(e.target.value);

                                  if (isEmail(e.target.value)) {
                                    cancelCheckIsPasswordConfigured();

                                    queue.push(String(e.target.value || "").toLowerCase());
                                    checkIsPasswordConfigured(String(e.target.value || "").toLowerCase());
                                  }
                                }}
                                onKeyDown={(e) =>
                                  e.key === "Enter" &&
                                  emailVal &&
                                  !checkIsPasswordConfigured(emailVal) &&
                                  loginRequest({ data: { email: emailRef, password: passwordRef, service, checkPreviousAuthToken: true } })
                                }
                              />
                            </label>
                            <Fragment key={isPasswordConfiguredState}>
                              {cache(emailVal) !== false && (
                                <label htmlFor="password">
                                  <input
                                    ref={passwordRef}
                                    id="password"
                                    placeholder="Password"
                                    className="password"
                                    type="password"
                                    onBlur={() => {
                                      wakenMss();
                                    }}
                                    onChange={(e) => {
                                      setPasswordVal(e.target.value);
                                    }}
                                    onKeyDown={(e) =>
                                      e.key === "Enter" &&
                                      emailVal &&
                                      passwordVal &&
                                      loginRequest({
                                        data: { email: emailRef, password: passwordRef, service, checkPreviousAuthToken: true },
                                      })
                                    }
                                  />
                                </label>
                              )}
                            </Fragment>
                          </fieldset>
                          <div className="button">
                            <CrButton
                              loading={loginResponse.busy}
                              disabled={loginResponse.busy || !emailVal || (!passwordVal && checkIsPasswordConfigured(emailVal))}
                              color="primary"
                              onClick={() =>
                                emailVal &&
                                loginRequest({ data: { email: emailRef, password: passwordRef, service, checkPreviousAuthToken: true } })
                              }
                            >
                              Sign in
                            </CrButton>
                            <p>
                              {process.env.REACT_APP_CDMS_HOST && (
                                <a href={`${process.env.REACT_APP_CDMS_HOST}/auth/password/find`} target="_blank" rel="noopener noreferrer">
                                  Forgot Password?
                                </a>
                              )}
                              {!process.env.REACT_APP_CDMS_HOST && <a href="/auth/password/find">Forgot Password?</a>}
                            </p>
                          </div>
                        </div>
                        <strong className="copyright">
                          <div className="privacy-policy">
                            <a href="https://mvn.do/legal/privacy" target="_blank" rel="noopener noreferrer">
                              Privacy Policy
                            </a>{" "}
                          </div>
                          Copyright &copy; 2020-{new Date().getFullYear()}{" "}
                          <a href="https://www.jnpmedi.com" target="_blank" rel="noopener noreferrer">
                            JNPMEDI
                          </a>{" "}
                          all rights reserved.
                        </strong>
                        {/* Dialogs */}
                        <InitPasswordDialog
                          messages={initPasswordDialog?.messages}
                          visibility={initPasswordDialogVisibility}
                          email={emailRef}
                          currentPassword={passwordRef}
                          passwordUpdatedEventHandler={(password) => {
                            setInitPasswordDialogVisibility(false);
                            loginRequest({ data: { email: emailRef, password, service } });
                          }}
                          cancelEventHandler={() => setInitPasswordDialogVisibility(false)}
                        />
                        <InitEmailVerifyPasswordDialog
                          key={`${emailRef.current?.value || "none"}`}
                          messages={initEmailVerifyPasswordDialog.messages}
                          visibility={initEmailVerifyPasswordDialogVisibility}
                          email={emailRef}
                          passwordUpdatedEventHandler={(password) => {
                            setInitEmailVerifyPasswordDialogVisibility(false);
                            loginRequest({ data: { email: emailRef, password, service } });
                          }}
                          cancelEventHandler={() => setInitEmailVerifyPasswordDialogVisibility(false)}
                          emailVal={emailVal}
                        />
                        <UpdatePasswordDialog
                          messages={updatePasswordDialog?.messages}
                          visibility={updatePasswordDialogVisibility}
                          email={emailRef}
                          currentPassword={passwordRef}
                          updatePasswordDoneEventHandler={(password) => {
                            setUpdatePasswordDialogVisibility(false);
                            loginRequest({ data: { email: emailRef, password, service } });
                          }}
                          cancelEventHandler={() => setUpdatePasswordDialogVisibility(false)}
                        />
                        <DuplicateLoginDialog
                          messages={duplicateLoginDialog?.messages}
                          visibility={duplicateLoginDialogVisibility}
                          confirmEventHandler={() => loginRequest({ data: { email: emailRef, password: passwordRef, service } })}
                          cancelEventHandler={() => setDuplicateLoginDialogVisibility(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      </Switch>
    </>
  );
}
